import React, { useState, useEffect } from "react";
import "../LoginForm/LoginForm.css";
import { useNavigate, useLocation } from "react-router-dom";
import { rangeNumber } from "../../../Utils/Utils";
import SelectSearcher from "../../SelectSearch/SelectSearch";
import {useTranslation} from 'react-i18next';
import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";


function SignupForm() {
  const navigate = useNavigate();
  const dataFromNavigate = useLocation();


  const allGenders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" }
  ];

  const allCivilsStatuses = [
    { label: "Single", value: "single" },
    { label: "Divorced", value: "divorced" },
    { label: "Married", value: "married" },
  ];
  const { t, i18n, ready } = useTranslation();
  const allAges = rangeNumber(18, 99);

  const [countryList, setCountryList] = useState([]);
  const [password, setPassword] = useState();
  const [username, setUsername] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [email, setEmail] = useState();
  const [age, setAge] = useState(dataFromNavigate.state?.age);
  const [gender, setGender] = useState(dataFromNavigate.state?.gender);
  const [genderPartner, setGenderPartner] = useState(dataFromNavigate.state?.genderPartner);
  const [civil, setCivil] = useState(dataFromNavigate.state?.civilStatus);
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [cityList, setCityList] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [isCreated, setIsCreated] = useState(false);

  const handleCountry = async (childrenData) => {
    setCountry(childrenData);
    const response = await axios.get(
      config.url + "countries/code/" + childrenData + "/cities"
    );
    let countryCities = [];
    if (response.data.cities) {
      countryCities = response.data.cities.map((city) => {
          return { value: city.id, label: city.name };
      });
    }
    setCityList(countryCities);
  };

  const [isPassword, setIsPassword] = useState(false);
  const [isUsername, setIsUsername] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isAge, setIsAge] = useState(false);
  const [isGender, setIsGender] = useState(false);
  const [isGenderPartner, setIsGenderPartner] = useState(false);
  const [isCivil, setIsCivil] = useState(false);
  const [isCountry, setIsCountry] = useState(false);
  const [isCity, setIsCity] = useState(false);

  useEffect(() => {
    getCountries();
  }, [countryList]);

  const getCountries = async () => {
    if (countryList.length === 0) {
      const response = await axios.get(config.url + "countries");
      response.data.countries.map((country) =>
        countryList.push({
          id: country.id,
          value: country.code,
          label: country.name,
        })
      );
    }
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  function postRegister(e) {
    e.preventDefault();

    setIsUsername(false);
    setIsPassword(false);
    setIsEmail(false);
    setIsConfirmPassword(false);
    setIsAge(false);
    setIsGender(false);
    setIsGenderPartner(false);
    setIsCivil(false);
    setIsCountry(false);
    setIsCity(false);


    if (validateEmail(email) === false) {
      setIsEmail("Please enter a valid email");
    }
    if (age === undefined) {
      setIsAge("Age must be between 18 and 99");
    }
    if (gender === undefined) {
      setIsGender("Please enter a valid gender");
    }
    if (genderPartner === undefined) {
      setIsGenderPartner("Please enter a valid gender for partner");
    }
    if (civil === undefined) {
      setIsCivil("Please enter a valid civil status");
    }
    if (country === undefined) {
      setIsCountry("Please enter a valid country");
    }
    if (city === undefined) {
      setIsCity("Please enter a valid city");
    }
    if (password !== confirmPassword) {
      setIsPassword("Passwords do not match");
      setIsConfirmPassword("Passwords do not match");
    }


    const country_id = countryList.filter(function (item) {
      return item.value == country;
    });
    if (
      username &&
      email &&
      age &&
      gender &&
      genderPartner &&
      civil &&
      country
    ) {
      axios
        .post(config.url + "register", {
          username: username,
          email: email,
          password: password,
          password_confirmation: confirmPassword,
          age: age,
          country_id: country_id[0].id,
          city_id: city,
          gender: gender,
          looking_for: genderPartner,
          civil_status: civil,
        })
        .then((result) => {
          if (result.status === 201) {
            setUserErrorMessage("");
            setIsCreated(true);
            setUserMessage(
              "Your account has been successfully created. In order to be able to use it, first you need to activate it by following the instructions sent on email.  In case of any issues please contact us."
            );
          }
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          let message = "";

          if(errors?.username == 'validation.unique' && errors?.email == 'validation.unique'){
            message = t("Username or Email is already in use. Please try again with a different email or username!")
          }
          else if(errors?.username == 'validation.unique'){
            message = t("Username is already exists. Please try again with a different username!")
          }
          else if(errors?.email == 'validation.unique'){
            message = t("Email is already registered. Please try again with a different email!")
          }
          else{
            Object.keys(errors).map(function (key, index) {
              message += t("The Following field is invalid: ") + key +" \n";
              // message += errors[key][0] + "\n";
            });
          }
          setUserErrorMessage(message);
          toast(message);
        });
    }
  }
  return (
    <div className="login-section padding-tb">
      <div className="container">
        <div className="login-form bb-color">
          <h3>
            <strong>Register Now</strong>
          </h3>
          <form
            style={{ textAlign: "center", marginTop: "20px" }}
            onSubmit={(e) => postRegister(e)}
          >
   {isCreated ? '' : <>
    <div className="form-login-item">
              <input
                className={"w-100" + (isUsername ? " red-error" : "")}
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                placeholder={t("Username")}
              />
            </div>
            {isUsername && <div className="error-message">{isUsername}</div>}
            <div className="form-login-item">
              <input
                className={"w-100" + (isEmail ? " red-error" : "")}
                type="text"
                value={email}
                id="email"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t("Email")}
              />
            </div>
            {isEmail && <div className="error-message">{isEmail}</div>}
            <div className="form-login-item">
              <input
                className={"w-100" + (isPassword ? " red-error" : "")}
                type="password"
                value={password}
                id="password"
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder={t("Password")}
              />
            </div>
            {isPassword && <div className="error-message">{isPassword}</div>}
            <div className="form-login-item">
              <input
                className={"w-100" + (isConfirmPassword ? " red-error" : "")}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                placeholder={t("Password confirm")}
              />
            </div>
            {isConfirmPassword && (
              <div className="error-message">{isConfirmPassword}</div>
            )}
            <div className="form-item-double">
              <div className="ages w-100">
                <select
                  id="gender"
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  {[{ label: t("Gender"), value: "" }]
                    .concat(allGenders)
                    .map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                </select>
                <select
                  id="age"
                  name="age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                >
                  {[t("Age")].concat(allAges).map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
            {isAge && <div className="error-message">{isAge}</div>}
            <div className="form-item-double">
              <div className="ages w-100">
                <select
                  className={isGenderPartner ? " red-error" : ""}
                  id="genderPartner"
                  name="genderPartner"
                  value={genderPartner}
                  onChange={(e) => setGenderPartner(e.target.value)}
                >
                  {[{ label: t("Looking for"), value: "" }]
                    .concat(allGenders)
                    .map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                </select>
                <select
                  id="civil"
                  className={isCivil ? " red-error" : ""}
                  name="civil"
                  value={civil}
                  onChange={(e) => setCivil(e.target.value)}
                >
                  {[{ label: t("Civil status"), value: "" }]
                    .concat(allCivilsStatuses)
                    .map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                </select>
              </div>
            </div>
            {isGenderPartner && (
              <div className="error-message">{isGenderPartner}</div>
            )}
            {isCivil && <div className="error-message">{isCivil}</div>}
            <div className="form-item-double">
              <div className="w-95">
                <SelectSearcher
                  parrentInputValue={handleCountry}
                  options={countryList}
                  name="country"
                  id="country"
                  error={isCountry ? "red-error" : ""}
                  placeholder={t("Select country")}
                />
              </div>
              <div className="w-95">
                <SelectSearcher
                  parrentInputValue={(childrenData) => setCity(childrenData)}
                  options={cityList}
                  name="city"
                  id="city"
                  error={isCity ? "red-error" : ""}
                  placeholder={t("Select city")}
                />
              </div>
            </div>
            </>}
            {isCountry && <div className="error-message">{isCountry}</div>}
            {isCity && <div className="error-message">{isCity}</div>}

            {userErrorMessage && (
              <div className="error-message">{userErrorMessage}</div>
            )}
            {userMessage && <div className="valid-message">{userMessage}</div>}

            {!isCreated && <button className="form-button tb-color f-white howver">
              {t("Get started now")}
            </button>}
            <div className="login-extra-row">
              <p>
                {t("Are you a member?")} <a className="link-bold link-white-button" onClick={() => navigate('/login')}> {t("Login")}</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
