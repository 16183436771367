import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo_centralpay from "../images/logo/logo_centralpay.png";

export default function Footer() {
    const { t } = useTranslation();
    const year = new Date().getFullYear(); // adaugat
    return (
        <footer>
            <div className="container">
                <div className="text-center mg-top-10">
                    <ul className="footer-links">
                        <li className="mg-left-10">
                            <Link className="link-white-light" to="/contact">
                                {t("Contact")}
                            </Link>
                        </li>
                        <li className="mg-left-10 ">
                            <Link className="link-white-light" to="/user-guide">
                                {t("User guide")}
                            </Link>
                        </li>
                        <li className="mg-left-10">
                            <Link
                                className="link-white-light"
                                to="/privacy-policy"
                            >
                                {t("Privacy policy")}
                            </Link>
                        </li>
                        <li className="mg-left-10">
                            <Link
                                className="link-white-light"
                                to="/terms-and-conditions"
                            >
                                {t("Terms and conditions")}
                            </Link>
                        </li>
                    </ul>
                    <div>
                        <p>
                            &copy; {year}{" "}
                            <a className="link-bold link-white-button" href="/">
                                {process.env.REACT_APP_APP_NAME}
                            </a>{" "}
                            {t(" - Don't miss your chance")}
                        </p>
                    </div>
                </div>
                <div className="d-flex-center mg-top-10">
                    <a href="https://customer.centralpay.net/home/9fe3d5af-6ab6-47e7-85b9-ad4cccb76d13?fbclid=IwAR1LK3uysAyqsmaqJbKrGO44job6uY-QYGCLoRD5Aatvqobj862c3us1AgQ">
                        <img src={logo_centralpay} alt="logo_central_pay" />
                    </a>
                </div>
            </div>
        </footer>
    );
}
